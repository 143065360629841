import { MasterTypes } from "../Constant/Enums";

export interface IApisCalls {
  getList: string;
  getById: string;
  save: string; // add new record and update record
  delete: string;
}
export interface IMasterEndPoints {
  gender: IApisCalls;
  category: IApisCalls;
  curriculum: IApisCalls;
  stream: IApisCalls;
  class: IApisCalls;
  payment: IApisCalls;
  credential: IApisCalls;
  batch: IApisCalls;
  document: IApisCalls;
  holiday: IApisCalls;
  status: IApisCalls;
  medium: IApisCalls;
  course: IApisCalls;
  platform: IApisCalls;
  task:IApisCalls;
  mailTemplates:IApisCalls;
  session:IApisCalls;
  room:IApisCalls;
  section:IApisCalls;
}

interface IMasters {
  type: MasterTypes;
  endPoints: IApisCalls;
  prefix: string;
  postfix: string;
}
const masterEndpoints: IApisCalls = {
  getList: "",
  getById: "",
  save: "",
  delete: "",
};
const MasterPrefix = "Masters";
const MasterPostfix = "";
export const BatchMaster: IMasters = {
  type: MasterTypes.Batch,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};

export const AssessmentCategoryMaster: IMasters = {
  type: MasterTypes.AssessmentCategory,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const CategoryMaster: IMasters = {
  type: MasterTypes.Category,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const ClassesMaster: IMasters = {
  type: MasterTypes.Classes,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const SubjectTypeMaster:IMasters={
  type: MasterTypes.SubjectTypes,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
}
export const CredentialMaster: IMasters = {
  type: MasterTypes.Credential,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const CurriculumMaster: IMasters = {
  type: MasterTypes.Curriculum,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const DocumentsMaster: IMasters = {
  type: MasterTypes.Documents,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const GenderMaster: IMasters = {
  type: MasterTypes.Gender,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const FeesTypeMaster: IMasters = {
  type: MasterTypes.FeesType,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const HolidaysMaster: IMasters = {
  type: MasterTypes.Holidays,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const PaymentMethodMaster: IMasters = {
  type: MasterTypes.PaymentMethod,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const StatusMaster: IMasters = {
  type: MasterTypes.Status,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const StreamMaster: IMasters = {
  type: MasterTypes.Stream,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const MediumMaster: IMasters = {
  type: MasterTypes.Medium,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const CourseMaster: IMasters = {
  type: MasterTypes.Course,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const SubjectsMaster: IMasters = {
  type: MasterTypes.Subjects,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const SemesterMaster: IMasters = {
  type: MasterTypes.Semester,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const DepartmentMaster: IMasters = {
  type: MasterTypes.Department,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const SourceMaster: IMasters = {
  type: MasterTypes.Source,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const AssignToMaster: IMasters = {
  type: MasterTypes.AssignTo,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const RoleMaster: IMasters = {
  type: MasterTypes.Role,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const ReasonMaster: IMasters = {
  type: MasterTypes.Reason,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const PlatformMaster: IMasters = {
  type: MasterTypes.Platform,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const ReminderMaster: IMasters = {
  type: MasterTypes.Reminder,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const TaskMaster: IMasters = {
  type: MasterTypes.Reminder,
  endPoints: masterEndpoints,
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const MailTemplatesMaster:IMasters={
  type:MasterTypes.MailTemplates,
  endPoints:masterEndpoints,
  prefix:MasterPrefix,
  postfix:MasterPostfix
}
export const SessionMaster:IMasters={
  type:MasterTypes.MailTemplates,
  endPoints:masterEndpoints,
  prefix:MasterPrefix,
  postfix:MasterPostfix
}
export const RoomMaster:IMasters={
  type:MasterTypes.Room,
  endPoints:masterEndpoints,
  prefix:MasterPrefix,
  postfix:MasterPostfix
}
export const SchemasMaster: IMasters = {
  type: MasterTypes.Course,
  endPoints: {
    getList: "Schemas",
    getById: "",
    save: "",
    delete: "",
  },
  prefix: MasterPrefix,
  postfix: MasterPostfix,
};
export const EnquiryEndPoint: IApisCalls = {
  getById: "GetById",
  getList: "GetList",
  save: "Save",
  delete: "",
};
export const SectionMaster:IMasters={
  type:MasterTypes.Section,
  endPoints:masterEndpoints,
  prefix:MasterPrefix,
  postfix:MasterPostfix
}