import { Modal, Tag, Button, Space, Table, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import FeesViewPdf from "./FeesViewPdf";
import {
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import AddEditFees from "./AddEditFees";

type FeesSummaryType = {
  tableData: {
    student: { id: ""; name: "" };
    id: string;
  };
  onDismiss: () => void;
};
const FeesSummary = ({ tableData, onDismiss }: FeesSummaryType) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [htmlContents, setHtmlContents] = useState<string>("");
  const [show, setShow] = useState("");
  const [index, setIndex] = useState(-1);
  const [propIndex, setPropIndex] = useState(-1);
  const [onDownloadLoading, setOnDownloadLoading] = useState(false); //Specific
  const [dataSource, setDataSource] = useState<any>();
  const [onDownloadLoadingBulk, setOnDownloadLoadingBulk] = useState(false); //Bulk
  const [loading, setLoading] = useState(false); //Bulk
  const [summaryData, setSummaryData] = useState<any>();
  const [isUpdate, setIsUpdate] = useState(false);
  const GetById = async () => {
    setLoading(true);
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.PAYMENT_BY_ID + "?",
      { id: tableData?.student?.id },
      {}
    );
    setLoading(false);
    if (res?.result) {
      setDataSource(() =>
        res?.result?.paymentDetail?.map((item: any, innerIndex: number) => ({
          ...item,
          key: innerIndex,
        }))
      );
    }
  };

  useEffect(() => {
    GetById();
  }, [tableData?.student?.id, isUpdate]);

  const viewPdf = async (indexAt: any) => {
    setIndex(indexAt);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.PAYMENT_GET_VIEW + "?",
      { id: tableData?.student?.id, index: indexAt }
    );
    if (res?.result) {
      setHtmlContents(res?.result);
      setShow("showPdf");
      setPropIndex(indexAt);
      setIndex(-1);
    } else {
      setHtmlContents("");
      setIndex(-1);
      setPropIndex(-1);
    }
  };

  const onDownload = async () => {
    setOnDownloadLoading(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.PAYMENT_DOWNLOAD,
      { id: tableData?.student?.id, index: propIndex }
    );
    if (res?.status) {
      setOnDownloadLoading(false);
    } else {
      setOnDownloadLoading(false);
    }
  };

  const downloadBulkPdf = async () => {
    setOnDownloadLoadingBulk(true);
    console.log("download",selectedRows);
    if (selectedRows.length!==0) {
      await Promise.all(
        selectedRows?.map(async (row: any) => {
          await ApiUtility.downloadMultipleFile(HTTPSCalls.ENDPOINTS.PAYMENT_BOTH_DOWNLOAD, {
            id: tableData?.student?.id,
            index: row?.key,
          });
        })
      );
    }
    else{
          await ApiUtility.downloadMultipleFile(HTTPSCalls.ENDPOINTS.PAYMENT_BOTH_DOWNLOAD, {
            id: tableData?.student?.id,
            index: propIndex,
          });
    }
    setOnDownloadLoadingBulk(false);
  };

  const openDrawer = (val: any) => {
    let value = {
      ...val,
      date: dayjs(val?.date),
      id: tableData?.id,
      medium: {
        label: val?.medium?.name,
        value: val?.medium?.id,
      },
      feesType: {
        label: val?.feesType?.name,
        value: val?.feesType?.id,
      },
      student: {
        label: tableData?.student?.name,
        value: tableData?.student?.id,
      },
    };
    setSummaryData(value);
    setShow("addEditDrawer");
  };

  const columnsModal: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (data: any) => <span>{dayjs(data).format("DD MMM YYYY")}</span>,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (data: any) => <span>{data?.name}</span>,
      // width: "40%",
    },
    {
      title: "Fees Type",
      dataIndex: "feesType",
      key: "feesType",
      render: (data: any) => <span>{data?.name}</span>,
      // width: "40%",
    },
    {
      title: "Medium Details",
      dataIndex: "mediumDetails",
      key: "mediumDetails",
      render: (data: any) => <span>{data}</span>,
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (data: any) => <span>{data}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "end",
      key: "amount",
      render: (data: any) => <span>₹ {data}</span>,
      // filterSearch: true,
      // width: "40%",
    },
    {
      title: "Edit",
      key: "view",
      render: (value, record, indexAt) => {
        return (
          <>
            <Space size={10}>
              <Button
                loading={index == record?.key}
                onClick={async () => {
                  await viewPdf(indexAt);
                }}
                icon={<EyeOutlined />}
              ></Button>
              {/* <DownloadBtn studentId={tableData?.name?._id}  index={index} ></DownloadBtn> */}

              {tableData?.id && (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => openDrawer(value)}
                />
              )}
            </Space>
          </>
        );
      },
      align: "center",
    },
    // {
    //     title: "",
    //     key: "Edit",
    //     render: (value, record, index) => <>

    //     </>
    // }
  ];

  return (
    <>
      <Modal
        open={true}
        onCancel={() => {
          onDismiss();
        }}
        width={"61vw"}
        title={<Tag color="blue">{tableData?.student?.name}</Tag>}
        closable={false}
        footer={
          <Space align="end" style={{ marginBottom: 16 }}>
            {selectedRows?.length > 0 ? (
              <Button
                onClick={() => downloadBulkPdf()}
                type="primary"
                ghost
                loading={onDownloadLoadingBulk}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            ) : null}
            <Button
              type="primary"
              ghost
              onClick={() => {
                onDismiss();
              }}
            >
              Cancel
            </Button>
          </Space>
        }
        destroyOnClose={true}
        forceRender={true}
      >
        <>
          <Table
            // className="cit-table"
            // loading={modelLoadingSpin}

            // caption={}
            // title={()=><>{selectedRows?.length>0? <Button>Download</Button>:null}</>}
            size="small"
            rowSelection={{
              type: "checkbox",
              onChange: (selectRowKeys, selectRow) => {
                setSelectedRows([...selectRow]);
              },
            }}
            loading={{
              spinning: loading,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            columns={columnsModal}
            dataSource={dataSource}
            pagination={false}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} className="fw600">
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={2}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={3}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={4}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={5}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={6}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell index={5} className="fw600" align="end">
                      ₹{" "}
                      {dataSource?.reduce((acc: any, curr: any) => {
                        return (acc += Number(curr?.amount));
                      }, 0) || 0}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </>
      </Modal>
      {show == "showPdf" && (
        <FeesViewPdf
          onDismiss={() => {
            setHtmlContents("");
            setShow("");
          }}
          htmlContents={htmlContents}
          onDownloadLoading={onDownloadLoading}
          onDownloadLoadingBulk={onDownloadLoadingBulk}
          onDownloadBulk={downloadBulkPdf}
          setHtmlContents={setHtmlContents}
        />
      )}
      {show == "addEditDrawer" && (
        <AddEditFees
          propsData={summaryData}
          onClose={(res: boolean) => {
            setShow("");
            setSummaryData("");
            if (res) setIsUpdate(!isUpdate);
          }}
        />
      )}
    </>
  );
};

export default FeesSummary;
