import React from 'react'
import { Avatar, Button, Col, Modal, Row } from 'antd'
import "./CallDetails.css";
import {PhoneFilled, UserOutlined } from '@ant-design/icons';

const InComingModel: React.FC<{
  isOpenIncoming: boolean;
  setInComingModel: any;
  data: any;
  setIsCallEnded:any;
  setRingModelOpen:any;
}> = (props) => {
  console.log(props,"testpppp");
  
  return (
    <>
      <Modal
        className="incomingModel"
        // mask={false}
        maskClosable={false}
         closable={false}
        open={props.isOpenIncoming}
        width={300}
        onCancel={() => props.setInComingModel(false)}
        footer={
          <Row justify="space-evenly">
            <Button
              onClick={() => {props.setInComingModel(false); props.setIsCallEnded(true)}}
              // disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
              style={{color:'red'}}
            >
              Ignore
            </Button>
            <Button
              className="ttp-btn-light btn-m-sm"
              style={{color:'green'}}
              // loading={btnLoading}
              onClick={() => {props.setInComingModel(false); props.setRingModelOpen(true)}}
            >
              Accept
            </Button>
          </Row>
        }
        style={{ top: "45%", left: "41%" }}
      >
        <div style={{
          height: "195px",
        }}>
          <Row style={{backgroundColor:"rgb(223, 246, 221)", padding:'4px'}} >
            <PhoneFilled rotate={100} style={{marginLeft:'4px', color:'green'}}  />
            <span style={{paddingLeft:'4px'}}>Test</span><span>+918542136529</span> 
            </Row>
            <Row >
          <Col lg={4}>
            <Avatar
              style={{
                marginTop: 5,
                marginLeft: 4,
                backgroundColor: "#e942f5",
                color: "#fff",
              }}
              size={35}
              icon={<UserOutlined />}
            />
          </Col>
          <Col style={{ textAlign: "start" }}>
            <p style={{ fontWeight: "bold" }}>Test </p>
            <p style={{ fontSize: 14, color: "gray", textAlign: "start" }}>
              +918542136529{props?.data?.phone}
            </p>
          </Col>
        </Row>
        </div>
      </Modal>
    </>
  )
}

export default InComingModel