import {
  Badge,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Flex,
  Input,
  Progress,
  ProgressProps,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useState, useEffect } from "react";
import {
  useBatchAttendance,
  useGetMaster,
  useGetStudentStatus,
} from "../../Utilities/tanStackQueries";
import { FeesType, MasterTypes } from "../../Constant/Enums";
import dayjs from "dayjs";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  ExclamationOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AddStudentAtt } from "../../Store/Slice/studentAttSlice";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import EChartsReact from "echarts-for-react";
import { color } from "echarts";
import FeesViewModal from "./FeesViewModal";
import { selectBatchState } from "../../Store/Slice/batchSlice";
const StudentAttendance = () => {
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [search, setSearch] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalPresent, setTotalPresent] = useState<any>(0);
  const [totalAbsent, setTotalAbsent] = useState<any>(0);
  const [sendFeesType, setsendFeesType] = useState("");
  const { data: batchMaster, isLoading: isMasterLoading } = useGetMaster({
    start: 0,
    length: 999,
    MasterType: MasterTypes.Batch,
  });
  // useEffect(() => {
  // if (batchMaster && batchMaster?.result?.items?.length > 0) {
  //   setSelectedBatch({
  //     value: batchMaster.result.items[0].id,
  //     label: batchMaster.result.items[0].name,
  //   });
  // }
  // }, [batchMaster]);
  const { data: studentStatus, isLoading: studentLoading } =
    useGetStudentStatus();
  const screenWidth=window.innerWidth;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "85%",
      left: "center",
      itemGap:screenWidth < 1564 ? 30 : 45
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["50%", "70%"],
        center: ["50%", "45%"],
        startAngle: 0,
        endAngle: 360,
        verticalAlign: "bottom",
        data: [
          {
            value: studentStatus?.result.inActive,
            name: `InActive  ${studentStatus?.result?.inActive || ""}`,
          },
          {
            value: studentStatus?.result.enroll,
            name: `Active  ${studentStatus?.result?.enroll || ""}`,
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  const option2 = {
    color: ["#377dff", "#ff7537"],
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "85%",
      itemGap:screenWidth < 1564 ? 30 : 45
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["50%", "70%"],
        center: ["50%", "45%"],
        startAngle: 0,
        endAngle: 360,
        verticalAlign: "bottom",
        data: [
          {
            value: studentStatus?.result.male,
            name: `Male ${studentStatus?.result.male || ""}`,
          },
          {
            value: studentStatus?.result.female,
            name: `Female ${studentStatus?.result.female || ""}`,
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const getBatchOptions = () => {
    return (
      batchMaster?.result?.items.map((b: any) => {
        return {
          label: b?.name,
          value: b?.id,
        };
      }) || []
    );
  };

  const navigation = useNavigate();
  const batchState = useSelector(selectBatchState)
  const {
    data: batchStudent,
    isLoading: batchStudentLoading,
    isFetched,
  } = useBatchAttendance({
    batchId: batchState,
    date: selectedMonth,
    name: search,
  });

  useEffect(() => {
    if (isFetched) {
    setTotalPresent(studentStatus?.result?.presentStudent)
    setTotalAbsent(studentStatus?.result?.absentStudent)
    }
  }, [isFetched,studentStatus]);

  const countPresent = async () => {
    let presentCount = 0;
    let absentCount = 0;
    await batchStudent?.forEach((item: any) => {
      if (item?.status === 1) {
        presentCount++;
      } else {
        absentCount++;
      }
    });
    setTotalPresent(presentCount);
    setTotalAbsent(absentCount);
  };
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <div style={{ display: "flex" }}>
          <span
            style={{
              display: "inline-block",
              height: "8px",
              backgroundColor:
                record?.status && record?.status === 1
                  ? "rgb(97, 200, 85)"
                  : "rgb(255, 115, 115)",
              borderRadius: "50%",
              flex: "0 0 8px",
              lineHeight: 1.2,
              alignSelf: "center",
              marginRight: "8px",
            }}
          ></span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                AddStudentAtt({
                  batch: {
                    id: record?.Batch?.id,
                    name: record?.Batch?.name,
                  },
                  student: {
                    id: record?.id,
                    name: record?.name,
                  },
                  date: dayjs.utc(selectedMonth),
                })
              );
              navigation("/attendance");
            }}
          >
            <span style={{ fontSize: "13px" }}>
              {text}
              {/* -{" "}
            <span style={{ color: "gray", fontSize: 12 }}>
              {record?.studentId || ""}
            </span> */}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: "In Time",
      dataIndex: "in",
      key: "in",
      render: (text: any, record: any) => (
        <Tooltip
          destroyTooltipOnHide
          placement="top"
          overlayStyle={{ minWidth: 160 }}
          title={record?.dayTrans?.map((item: any, index: number) => (
            <Row key={index} justify="space-between">
              <Col className="fs12" span={12}>
                {dayjs.utc(item?.time).format("hh:mm:ss A")}
              </Col>
              <Col className="fs12" span={11}>
                {item?.isManual && "Manual"}
              </Col>
            </Row>
          ))}
        >
          <span style={{ textAlign: "center", fontSize: "13px" }}>
            {/* {record)} */}
            {text ? dayjs.utc(text).format("hh:mm A") : "-"}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Out Time",
      dataIndex: "out",
      key: "out",
      render: (text: any, record: any) => {
        const dayTransLength = record?.dayTrans?.length || 0;
        return (
          <>
            <Tooltip
              destroyTooltipOnHide
              placement="top"
              overlayStyle={{ minWidth: 160 }}
              title={record?.dayTrans?.map((item: any, index: number) => (
                <Row key={index} justify="space-between">
                  <Col className="fs12" span={12}>
                    {dayjs.utc(item?.time).format("hh:mm:ss A")}
                  </Col>
                  <Col className="fs12" span={11}>
                    {item?.isManual && "Manual"}
                  </Col>
                </Row>
              ))}
            >
              <span style={{ textAlign: "center", fontSize: "13px" }}>
                {dayTransLength % 2 === 0 && dayTransLength !== 0
                  ? dayjs
                      .utc(record?.dayTrans[dayTransLength - 1]?.time)
                      .format("hh:mm A")
                  : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin
        spinning={studentLoading || batchStudentLoading}
        indicator={<LoadingOutlined />}
        fullscreen
      />
      <Row gutter={18}>
        <Col lg={16}>
          <Row gutter={18}>
            <Col lg={8}>
              <Card 
               className="dashboardCardes"
                style={{ height: 204 }}
              >
                <Typography className="fs17 fw600" style={{color:"rgb(54, 70, 99)",fontFamily:"Open Sans, sans-serif"}}>Enquiry</Typography>
                <Row style={{ lineHeight: 3 }} className="mt-3">
                  <Col lg={24}>
                    <Row>
                      <Col xl={18} xxl={18} lg={18}>
                        <Badge color="green" text="Enroll" />
                      </Col>
                      <Col xl={6} xxl={6} lg={6}>
                        <p style={{ fontWeight: 500 }}>
                          {" "}
                          {studentStatus?.result?.enroll}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={18} xxl={18} lg={18}>
                        <Badge color="blue" text="Hold" />
                      </Col>
                      <Col xl={6} xxl={6} lg={6}>
                        <p style={{ fontWeight: 500 }}>
                          {" "}
                          {studentStatus?.result?.hold}{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={18} xxl={18} lg={18}>
                        <Badge color="red" text="Rejected" />
                      </Col>
                      <Col xl={6} xxl={6} lg={6}>
                        {" "}
                        <p style={{ fontWeight: 500 }}>
                          {studentStatus?.result?.rejected}
                        </p>{" "}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={8}>
              <Card
                className="dashboardCardes"
                style={{
                  height: 204,
                }}
              >
                <Typography className="fs17 fw600" style={{color:"rgb(54, 70, 99)",fontFamily:"Open Sans, sans-serif"}}>Student Type</Typography>
                <EChartsReact
                  option={option2}
                  style={{ height: "140px", width: "100%" }}
                />
              </Card>
            </Col>
            <Col lg={8}>
              <Card
                className="dashboardCardes"
                style={{ height: 204 }}
              >
                <Typography className="fs17 fw600" style={{color:"rgb(54, 70, 99)" ,fontFamily:"Open Sans, sans-serif"}}>Active/InActive</Typography>
                <div style={{ width: "100%" }}>
                  <EChartsReact
                    option={option}
                    style={{ height: "140px", width: "100%" }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={18} className="mt-3">
            <Col lg={12}>
              <Card
                className="dashboardCardes"
                style={{
                  height: 204,
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <Typography className="fs17 fw600 " style={{color:"rgb(54, 70, 99)",fontFamily:"Open Sans, sans-serif"}}>Total Fees</Typography>
                <Row gutter={16}>
                  <Col span={8}>
                    <Card
                      bordered={false}
                      className="cardHover pt-0 mt-3"
                      style={{
                        cursor: "pointer",
                        boxShadow: "2px 0px 8px 4px #f8f8f8",
                        textAlign: "center",
                        height:"118px"
                      }}
                      onClick={() => {
                        showModal();
                        setsendFeesType(FeesType.Paid);
                      }}
                    >
                   <Row justify={'center'} className="pt-1"> <Col> <Badge color="green" style={{marginLeft:"-8px"}}/> </Col> <Col> <p className="fw600" style={{textAlign:"center" ,fontFamily:"Open Sans, sans-serif",marginLeft:"8px"}}>Paid</p></Col></Row>
                      <Row >
                     
                      <Col lg={24} className="pt-4 paymentStatus">
                       <p style={{color:"green",fontSize:"20px"}}>{studentStatus?.result?.paidFees || 0}</p>
                        
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={16}>
                    <Card
                      bordered={false}
                      className="cardHover paymentStatus mt-3"
                      style={{
                        cursor: "pointer",
                        boxShadow: "2px 0px 8px 4px #f8f8f8",
                        textAlign: "center",
                      }}
                    >
                      {/* <Statistic
                        title={<p style={{textAlign:"center"}}>Due</p>}
                        value={`${studentStatus?.result?.dueFees} | 00 `}
                        // precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        // prefix={<ExclamationOutlined />}
                        // suffix="%"
                      /> */}
                     <Row style={{marginLeft:"-8px"}} justify={'center'}><Col><Badge color="red"/></Col> <Col ><p className="fw600" style={{marginLeft:8}}>Due</p></Col> </Row>
                      <Row gutter={18}>
                        <Col lg={12} className="pt-2">
                          <Card
                            className="paymentStatus"
                            onClick={() => {
                              showModal();
                              setsendFeesType(FeesType.Due);
                            }}
                          >
                            <h5 style={{color:""}}>Available</h5>
                            <p style={{color:"#f62d38"}} className="fw600">{studentStatus?.result?.dueStudentFees || 0}</p>
                          </Card>
                        </Col>
                        <Col lg={12} className="pt-2">
                          <Card
                            className="paymentStatus"
                            onClick={() => {
                              showModal();
                              setsendFeesType(FeesType.DueStudentLeft);
                            }}
                          >
                            <h5 style={{color:""}}> Left</h5>
                            <p style={{color:"#f62d38"}} className="fw600">{studentStatus?.result?.dueStudentLeftFees || 0}</p>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                    <FeesViewModal
                      sendFeesType={sendFeesType}
                      isModalOpen={isModalOpen}
                      handleCancel={handleCancel}
                      setIsModelOpen={setIsModalOpen}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={12}>
              <Card
                className="dashboardCardes"
                style={{ height: 204,padding:"2px 10px 0 4px" }}
              >
                <Row justify={"space-between"}>
                  <Col>
                    <Typography
                      className="fs17 fw600"
                      style={{ color: "rgb(54, 70, 99)" ,fontFamily:"Open Sans, sans-serif" }}
                    >
                      Total Students
                    </Typography>
                  </Col>
                  <Col>
                    <Typography>{totalPresent + totalAbsent}</Typography>
                  </Col>
                  {/* <Divider
                      style={{ marginBottom: "2px", marginTop: "15px" }}
                      /> */}
                </Row>
                <Progress
                  style={{ margin: "6px 0" }}
                  percent={100}
                  success={{
                    percent:
                      (totalPresent * 100) / (totalPresent + totalAbsent),
                    strokeColor: undefined,
                  }}
                  strokeColor="#f85768"
                />
                <Row style={{ lineHeight: 3,padding:4,paddingRight:12 }}>
                  
              
                      <Col xl={16} xxl={16} lg={16}>
                        <Badge color="green" text="Present" />
                      </Col>
                      <Col xl={6} xxl={6} lg={6}>
                        <p style={{ fontWeight: 500 }}> {totalPresent}</p>
                      </Col>
                      <Col xl={2} xxl={2} lg={2}>
                        <p style={{ fontWeight: 500 }}>
                          {totalPresent&&totalPresent?(
                            (totalPresent * 100) /
                            (totalPresent + totalAbsent)
                          ).toFixed(2) : 0}
                          %
                        </p>
                      </Col>
                    </Row>
                    <Row style={{padding:4,paddingRight:12}}>
                      <Col xl={16} xxl={16} lg={16}>
                        <Badge color="#f85768" text="Absent" />
                      </Col>
                      <Col xl={6} xxl={6} lg={6}>
                        <p style={{ fontWeight: 500 }}>{totalAbsent}</p>
                      </Col>
                      <Col xl={1} xxl={1} lg={1}>
                        <p style={{ fontWeight: 500 }}>
                          {totalPresent&&totalAbsent?(
                            (totalAbsent * 100) /
                            (totalPresent + totalAbsent)
                          ).toFixed(2) : 0}
                          %
                        </p>
                      </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {/* <Row gutter={18} className="mt-3">
            <Col lg={8}>
              <div className="py-2 roundedCornerMedium bg-white" style={{ height: 266, display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
            </Col>
            <Col lg={8}>
              <div className="py-2 roundedCornerMedium bg-white" style={{ height: 266, display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
            </Col>
            <Col lg={8}>
              <div className="py-2 roundedCornerMedium bg-white" style={{ height: 266, display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
            </Col>

          </Row> */}
        </Col>
        <Col lg={8}>
          <div className="py-2 roundedCornerMedium bg-white">
            <Row gutter={18} style={{ padding: 12 }}>
              <Col lg={8} xxl={8} xl={8} md={8} offset={8}>
                <DatePicker
                  allowClear={false}
                  value={selectedMonth}
                  style={{ width: "100%" }}
                  // picker="month"
                  format="DD/MM/YYYY"
                  disabledDate={(current: any) => {
                    return current && current > dayjs().endOf("month");
                  }}
                  onChange={(val: any) => {
                    setSelectedMonth(val);
                  }}
                />
              </Col>
              {/* <Col lg={8} xxl={8} xl={8} md={8}>
                <Select
                  loading={isMasterLoading}
                  popupClassName="cit-select-box"
                  placeholder={"Select Batch"}
                  allowClear
                  onClear={() => {
                    setSelectedBatch("");
                  }}
                  showSearch
                  labelInValue
                  value={selectedBatch}
                  options={getBatchOptions()}
                  style={{ width: "100%" }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  onSelect={(val: any) => {
                    setSelectedBatch(val);
                  }}
                />
              </Col> */}
              <Col lg={8} xxl={8} xl={8} md={8}>
                <Search
                  size="middle"
                  placeholder="Search..."
                  allowClear
                  className="att-search-input"
                  onSearch={(val: string) => {
                    setSearch(val);
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Table
              // loading={{
              //   spinning: batchStudentLoading,
              //   indicator: <Spin indicator={<LoadingOutlined />} />,
              // }}
              style={{
                width: "100%",
                borderBottom: "none",
                maxHeight: 730,
                overflow: "auto",
                minHeight: 717,
              }}
              size="small"
              pagination={false}
              dataSource={batchStudent}
              columns={columns}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "rgb(97, 200, 85)",
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 10 }}>Present</span>
              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "rgb(255, 115, 115)",
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 10 }}>Absent</span>

              {/* <span
              style={{
                display: "inline-block",
                height: "8px",
                backgroundColor: "purple",
                borderRadius: "50%",
                flex: "0 0 8px",
                alignSelf: "center",
                marginRight: "5px",
              }}
            ></span>
    <span style={{marginRight:10}}>OnLeave</span> */}

              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "gray",
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 10 }}>Holiday</span>
              {/* <span
              style={{
                display: "inline-block",
                height: "8px",
                backgroundColor: "#f0e015",
                borderRadius: "50%",
                flex: "0 0 8px",
                alignSelf: "center",
                marginRight: "5px",
              }}
            ></span>
            <span style={{marginRight:20}}>HalfDay</span> */}
            </div>
          </div>
          {/* </Card> */}
        </Col>
        {/* <Card > */}
      </Row>
    </>
  );
};
export default StudentAttendance;
