import {
  AndroidOutlined,
  AppleFilled,
  AppleOutlined,
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Statistic,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import CommonEnquiryList from "./CommonEnquiryList";
import EnquiryForm from "./EnquiryForm";
import { useEffect, useState } from "react";
import { CandidateRequestType, CenterVisited, CenterVisitedOptions, CommonEnqListType, MasterTypes, processStepsOptions } from "../Constant/Enums";
import EnquiryFormStudents from "./EnquiryFormStudents";
import { useNavigate } from "react-router-dom";
import EnquiryLead from "./EnquiryLead";
import HTTPSCalls from "../Services/HTTPCalls";
import { ApiUtility } from "../Utilities/ApiUtility.axios";
import Search from "antd/es/input/Search";
const { Title, Paragraph, Text, Link } = Typography;
let userType: any = JSON.parse(localStorage.getItem("tp-user") + "")?.role;

const Admission_Enquiry = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [masterData, setMasterData] = useState<{
    status: any;
  }>({
    status: [],
  });
  const [show, setShow] = useState("Admission/Enquiry");
  const [editRecord, setEditRecord] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [empList, setEmpList] = useState([]);
  // Drawer states and functions
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [basicDetailFrm, setBasicDetailFrm] = useState<any>({});
  const [credentialFrm, setCredentialFrm] = useState<any>({});
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    filterId: "",
    nameSearch: "",
    batchSearch: "",
    sortCol: "",
    sortDir: "",
    userRoleId: "",
  });

  //#region  For New List Params CV
  const [isCSVDownloading, setIsCSVDownloading] = useState<boolean>(false);
  const [listParams1, setListParams1] = useState({
    filterId: "",
    nameSearch: "",
    batchSearch: "",
    sortCol: "",
    sortDir: "",
    userRoleId: "",
    fatherName: "",
    phoneNo: 0,
    centerVisited: 0,
    responseType: 0,
    counselors: [""],
    processStep: 0,
    start: 0,
    length: 15,
    type:CandidateRequestType.Enquire
  });
  const [pagingItems1, setPagingItems1] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const changeListParams = (key: string, value: any) => {
    console.log(key, value);

    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setListParams1((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  console.log("listParams1", listParams1);

  const downloadCSV = async () => {
    setIsCSVDownloading(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.ENQUIRY_CSV_DOWNLOAD,
      {
        requestType: CandidateRequestType.Enquire,
        filterId: listParams1?.filterId,
        batchSearch: listParams?.batchSearch,
        nameSearch: listParams1?.nameSearch,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams1.sortCol,
        sortDir: listParams1?.sortDir,
        // userRoleId: listParams?.userRoleId,
        userRoleId: listParams1?.userRoleId,
      }
    );
    if (res?.status) {
      setIsCSVDownloading(false);
    } else {
      setIsCSVDownloading(false);
    }
  };
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        status: any;
      } = {
        status: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Status
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };
  //#endregion

  const getList = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_USER_LIST + "?",
      listParams
    );
    if (res?.result?.items?.length > 0) {
      setEmpList(
        res?.result?.items?.map((item: any) => ({
          value: item?.id,
          label: item?.name?.first,
        }))
      );
    }
  };
  const showEditComponent = (data: any) => {
    setEditRecord(data);
    setShow("editRecord");
  };
  const onDismiss = () => {
    // setEditRecord(undefined);
    setShow("Admission/Enquiry");
  };
  const items: any = [
    {
      key: "1",
      label: "All Leads",
      children: (
        <>
          <CommonEnquiryList
            columns={{ all: true }}
            filter={CommonEnqListType.all}
            setEditRecord={showEditComponent}
            listParams1={listParams1}
            setListParams1={setListParams1}
            changeListParams={changeListParams}
            options={empList}
          />
        </>
      ),
      // icon:
      //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
      //     <div style={{ fontSize: "10px" }}>
      //       1000
      //     </div>
      //   </Avatar>
    },
    // {
    //   key: '2',
    //   label: 'New Leads',
    //   children: <CommonEnquiryList columns={{ all: true }} filter={CommonEnqListType.all} />,
    //   icon:
    //     <Avatar style={{ borderRadius: "10px", height: "20px" }}>
    //       <div style={{ fontSize: "10px" }}>
    //         100
    //       </div>
    //     </Avatar>
    // },
    // {
    //   key: '3',
    //   label: 'Unassigned Leads',
    //   children: <CommonEnquiryList columns={{ all: true }} filter={CommonEnqListType.all} />,
    //   icon:
    //     <Avatar style={{ borderRadius: "10px", height: "20px" }}>
    //       <div style={{ fontSize: "10px" }}>
    //         100
    //       </div>
    //     </Avatar>
    // },
    // {
    //   key: '4',
    //   label: "Today's Activity",
    //   children: <CommonEnquiryList columns={{ all: true }} filter={CommonEnqListType.all} />,
    //   icon:
    //     <Avatar style={{ borderRadius: "10px", height: "20px" }}>
    //       <div style={{ fontSize: "10px" }}>
    //         100
    //       </div>
    //     </Avatar>
    // },
    // {
    //   key: '5',
    //   label: "Missed Activity",
    //   children: <CommonEnquiryList columns={{ all: true }} filter={CommonEnqListType.all} />,
    //   icon:
    //     <Avatar style={{ borderRadius: "10px", height: "20px" }}>
    //       <div style={{ fontSize: "10px" }}>
    //         100
    //       </div>
    //     </Avatar>
    // },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    getList()
    getMaster()
  }, [])

  const enquiryTypeOptions:{ label: string, value: CandidateRequestType }[] =[
    { label: 'All', value: CandidateRequestType.Undefine },
    { label: 'Student', value: CandidateRequestType.Student },
    { label: 'Enquiries', value: CandidateRequestType.Enquire },
  ]
  const [enquiryType, setEnquiryType] = useState<{ label: string, value: CandidateRequestType }>({ label: 'Enquiries', value:CandidateRequestType.Enquire },);

  return (
    <>
      {show === "Admission/Enquiry" && (
        <>
          <Row justify={"end"}>
            <Col>
              <Typography>
                {/* <Text>Admission/Enquiry</Text> */}
                {/* <Title level={5} style={{ margin: "0px" }}>
                  Admission/Enquiry
                </Title> */}
              </Typography>
            </Col>
            {/* <Col>
              <Button icon={<PlusOutlined />} type="primary"
                onClick={() => {
                  // setEditId("");
                  // onOpen();
                  setShow("AddEnquiry")
                  // navigate("addEditEnquiry")
                }}
              >
                Add Enquiry
              </Button>
            </Col> */}
          </Row>
          {/* <Card >
            <Row>
              <Col span={4}>
                <Statistic title="Total Leads" style={{ fontSize: 500 }} value={100} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
              </Col>
              <Col span={4}>
                <Statistic title="Open" value={20} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
              </Col>
              <Col span={4}>
                <Statistic title="In progresses to enroll" value={112893} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
              </Col>
              <Col span={4}>
                <Statistic title="Enroll" value={112893} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
              </Col>
              <Col span={4}>
                <Statistic title="Lost/Not Interested" value={112893} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
              </Col>
              <Col span={4}>
                <Statistic title="DNC" value={112893} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
              </Col>
            </Row>
          </Card> */}
          {/* <Card style={{ marginTop: "14px" }}> */}
          <div  className="py-3 px-3 roundedCornerMedium bg-white">
            <Row gutter={[6, 10]}>
              <Col span={18} >
                <Space.Compact block>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      // setEditId("");
                      // onOpen();
                      setShow("AddEnquiry");
                      // navigate("addEditEnquiry")
                    }}
                  >
                    Add Enquiry
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    className="cit-add-btn"
                    onClick={() => {
                      downloadCSV();
                    }}
                    icon={<DownloadOutlined />}
                    loading={isCSVDownloading}
                  >
                    CSV
                  </Button>
                </Space.Compact>{" "}
              </Col>
              <Col span={2}>
                {userType === "ADMIN" ? (
                  <Select
                  placeholder="Select Student Type"
                  options={enquiryTypeOptions}
                  value={enquiryType}
                  labelInValue
                  onChange={(e) => {
                    setEnquiryType(e);
                    changeListParams("type",e?.value)
                  }}
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  />
                ) : null}
              </Col>
              <Col span={4}>
                {userType === "ADMIN" ? (
                  // <Select
                  //   showSearch
                  //   allowClear
                  //   onClear={() => {
                  //     changeListParams("userRoleId", "");
                  //   }}
                  //   style={{ width: "100%" }}
                  //   placeholder="Select Enquiry"
                  //   optionFilterProp="children"
                  //   labelInValue
                  //   filterOption={(input: any, option: any) =>
                  //     (option?.label ?? "").includes(input)
                  //   }
                  //   filterSort={(optionA, optionB) =>
                  //     (optionA?.label ?? "")
                  //       .toLowerCase()
                  //       .localeCompare((optionB?.label ?? "").toLowerCase())
                  //   }
                  //   options={empList}
                  //   onSelect={(val) => {
                  //     changeListParams("userRoleId", val?.value);
                  //   }}
                  // />
                  <Search
                  size="middle"
                  placeholder="Search Name..."
                  allowClear
                  className="att-search-input mb-1"
                  onSearch={(val: string) =>
                    changeListParams("nameSearch", val)
                  }
                  // onChange={(e: any) =>
                  //   e.target.value === "" ? setListParamsAndRefresh("") : null
                  // }
                  style={{ width: "100%" }}
                />
                ) : null}
              </Col>
              {/* <Col span={3}>
                {userType === "ADMIN" ? (
                  <Select
                    showSearch
                    allowClear
                    onClear={() => {
                      changeListParams("filterId", "");
                    }}
                    style={{ width: "100%" }}
                    placeholder="Select Status"
                    optionFilterProp="children"
                    labelInValue
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={masterData?.status}
                    onSelect={(val) => {
                      // console.log(val);
                      changeListParams("filterId", val?.value);
                    }}
                  />
                ) : null}
              </Col> */}
            </Row>
            <Row>

              <Col span={24}>
                <Tabs defaultActiveKey="1" items={items} />
                {/* <Tabs
              defaultActiveKey="2"
              const id = String(i + 1);
              items={[AppleOutlined, AndroidOutlined].map((Icon, i) => {
                return {
                  key: id,
                  label: `Tab ${id}`,
                  children: `Tab ${id}`,
                  icon: <Icon />,
                };
              })}
            /> */}
              </Col>
            </Row>
          {/* </Card> */}
          </div>
        </>
      )}

      {show === "AddEnquiry" && (
        <EnquiryFormStudents
          userType={userType}
          isOpen={isOpen}
          apiData={undefined}
          type={CandidateRequestType.Enquire}
          setIsLoading={setIsLoading}
          basicDetail={basicDetailFrm}
          credentialDetail={credentialFrm}
          onDismiss={(rec: boolean) => {
            setIsOpen(false);
            setBasicDetailFrm(null);
            setCredentialFrm(null);
            setEditId("");
            setShow("Admission/Enquiry");
            onClose();
            if (rec) setShouldRefresh((x) => !x);
          }}
        />
      )}

      {show === "editRecord" && (
        <EnquiryLead editRecord={editRecord} onDismiss={onDismiss} />
      )}
    </>
  );
};

export default Admission_Enquiry;
