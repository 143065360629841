import { useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../Services/HTTPCalls";
import { queryClient } from "../App";
import { Mutation } from "react-query";
import { MasterTypes } from "../Constant/Enums";
import { useSelector } from "react-redux";
import { selectBatchState } from "../Store/Slice/batchSlice";

// import axios from "axios";
// const batchState= useSelector((state:any)=>state.batch)

//For getting Schedule list name and id
export const GetListWithIdName = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_LIST_WITH_ID_NAME, payload);
};

export const useGetListWithIdName = (payload: any) => {
  return useQuery({
    queryKey: ["GetListWithIdName"],
    queryFn: () => GetListWithIdName(payload),
  });
};

export const getScheduleById = async (scheduleId: any = {}) => {
  return HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.SCHEDULE_GET_BY_ID + "?id=" + scheduleId,
    {}
  );
};

export const useGetScheduleById = (scheduleId: any = "") => {
  return useQuery({
    queryKey: ["getScheduleById", scheduleId],
    queryFn: () => getScheduleById(scheduleId),
    enabled: !!scheduleId,
  });
};

//For getting Room list name and id
export const getMaster = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?", payload);
};

export const useGetMaster = (payload: any) => {
  return useQuery({
    queryKey: ["GetMaster", payload],
    queryFn: () => getMaster(payload),
  });
};

//For getting Schedule list items by Schedule id
export const getScheduleItemListById = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.SCHEDULE_ITEM_LIST + "?", payload);
};

export const useGetScheduleItemListById = (payload: any = "") => {
  return useQuery({
    queryKey: [
      "getScheduleItemListById",
      payload?.scheduleId,
      payload?.sectionId,
    ],
    queryFn: () => getScheduleItemListById(payload),
    enabled: !!payload?.scheduleId && !!payload?.sectionId,
  });
};

//For getting Schedule list items by Schedule id
export const GetEnquiryListWithIdName = async (search: string = "") => {
  return HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.GET_ENQUIRY_LIST_WTIH_IDNAME + "?",
    { search }
  );
};

export const useGetEnquiryListWithIdName = (
  modelOpen: boolean = false,
  search: string = ""
) => {
  return useQuery({
    queryKey: ["EnquiryListWithIdName", search],
    enabled: modelOpen,
    queryFn: () => GetEnquiryListWithIdName(search),
  });
};

export const GetSubjectTypes = async (payload: any = {}) => {
  return HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.POST_MASTER + "?", payload);
};

export const UseSubjectTypes = (payload: any = {}, refresh: boolean) => {
  return useQuery({
    queryKey: ["GetSubjectTypes", payload, refresh],
    queryFn: () => GetSubjectTypes(payload),
  });
};

export const enquiryCommunicationGetById = async (payload: any) => {
  return await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.EnquiryCommunication_GETBYID + "?",
    payload
  );
};

export const useEnquiryCommunicationGetById = (payload: any) => {
  return useQuery({
    queryKey: ["CommunicationGetById", payload],
    queryFn: () => enquiryCommunicationGetById(payload),
    enabled: !!payload?.Id
  });
};

export const batchAttendance = async (payload: any) => {
  let res = await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.GET_ALL_STUDENT_ATTENDANCE + "?",
    payload
  );
  let data = res?.result?.flatMap((el: any) => {
    if (el?.records && el?.records?.length > 0) {
      return el.records.map((item: any) => {
        return {
          name: el.name,
          id: el.id,
          Batch: el.batch,
          ...item,
        };
      });
    } else {
      return {
        name: el.name,
        id: el.id,
        studentId: el.studentId,
        Batch: el.batch,
        // ...el
      };
    }
  });
  return data;
};

export const useBatchAttendance = (payload: any) => { 
  return useQuery({
    queryKey: ["GetAllStudentAttendance", payload],
    queryFn: () => batchAttendance(payload),
    // enabled:payload?.batchId?true:false
  });
};

export const getStudentStatus = async (batchState:any) => {
  return await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.GET_STUDENT_STATUS+"?" ,
    {
      batchId:batchState
    }
  );
};

export const useGetStudentStatus = () => {
  const batchState = useSelector(selectBatchState);

  return useQuery({
    queryKey: ["getStudentStatus", batchState], 
    queryFn: () => getStudentStatus(batchState),
  });
};