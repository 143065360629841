import * as React from "react";
import { useContext, useState, useEffect } from "react";
import MainLayout from "./Common/MainLayout";
import ApplicationState from "./Context/ApplicationState";
import TestLayout from "./Common/TestLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CallDetails from "./component/Call/CallDetails";
import { AccessCodes, IAccessCodes } from "./Common/Sidemenus";
import { useNavigate } from "react-router-dom";

export const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      staleTime:1000*6*1
    }
  }
});
const App = () => {
  const [rights, setRights] = useState<IAccessCodes | null>(null);
  const navigate = useNavigate();  

  useEffect(() => {
    const userData = localStorage.getItem("tp-user");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      setRights(parsedUserData?.rights || null);
    } else {
      setRights(null)
    }
  }, [navigate]); 

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {/* {rights?.enquiries === AccessCodes.enquiries &&<IncomingCall/>} */}
      {rights?.enquiries === AccessCodes.enquiries &&<CallDetails/>}
      <MainLayout />
    </QueryClientProvider>
    // <React.Fragment>
    /* <TestLayout /> */
    /* </React.Fragment> */
  );
};

export default App;
