import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "antd";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ColumnsType } from "antd/es/table/interface";
import { pageSizeOption } from "../LMS/SubjectAlllocation/SubjectAllocation";
import { FeesType } from "../../Constant/Enums";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../Store/Slice/batchSlice";

const FeesViewModal = (props: any) => {
  const { isModalOpen, handleCancel,sendFeesType } = props;
  const [viewFeesData, setViewFeesData] = useState<any>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [modelLoading, setModelLoading] = useState<boolean>(false);

  const [listParams, setListParams] = useState({
    streamSearch: "",
    batchSearch: "",
    start: 0,
    length: 15,
    search: "",
    sortCol: "Student.Name",
    sortDir: "ascend",
    feesType:"",
  });
  const batchState = useSelector(selectBatchState)
  const getFeesData = async () => {
    setModelLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.PAYMENT_GET_LIST + "?",
      {
        streamSearch: listParams?.streamSearch,
        // batchSearch: listParams?.batchSearch,
        batchSearch: batchState,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams.sortCol,
        sortDir: listParams?.sortDir,
        search: listParams?.search,
        feesType:sendFeesType,
      }
    );
    if ( res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      setViewFeesData(() =>
        result?.map((itm: any, index: number) => {
          return {
            sno: itm?.sno,
            key: index,
            student: itm?.student,
            id: itm?.id,
            paymentDetail: itm?.paymentDetail?.map(
              (item: any, innerIndex: number) => ({ ...item, key: innerIndex })
            ),
            totalPaidAmount: itm?.totalPaidAmount,
            remainingAmount: itm?.remainingAmount,
            feesAmount: itm?.feesAmount,
            stream: itm?.stream,
            batch: itm?.batch,
          };
        })
      );
      setPagingItems((p) => {
        p.totalRecords = res?.result?.totalRecords;
        return p;
      });
      setModelLoading(false);
    }
    setModelLoading(false);
  };
  useEffect(() => {
    getFeesData();
  }, [listParams, pagingItems,isModalOpen]);
  const columns: ColumnsType<any> = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "student",
      key: "name",
      width: "20%",
      render: (student: any) => <span>{student?.name}</span>,
    },
    {
      title: "Fees Amount",
      dataIndex: "feesAmount",
      key: "amount",
      width: "12%",
      render: (name: any) => <span>₹ {name}</span>,
    },
    {
      title: "Paid Amount",
      dataIndex: "totalPaidAmount",
      key: "totalPaidAmount",
      render: (name: any) => <span>₹ {name}</span>,
      width: "10%",
    },
    {
      title: "Due Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      render: (name: any) => <span>₹ {name}</span>,
      width: "12%",
    },
];
  return (
    <>
      <Modal
      width={1100}
        footer={false}
        title="Fees"
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24} style={{ paddingTop: "10px" }}>
            <Table
              size="small"
              className={"Tabel-style"}
              dataSource={viewFeesData}
              loading={modelLoading}
              columns={columns}
              style={{ minHeight: 200 }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: pageSizeOption,
              }}
              onChange={(paging, filter, sort: any) => {
                sort?.order &&
                  setListParams({
                    ...listParams,
                    sortDir: sort?.order,
                    sortCol: sort?.columnKey,
                  });
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
              scroll={{ y: `calc(100vh - 330px)` }}
              //
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FeesViewModal;
