import React, { useEffect, useState } from "react";
import { Avatar, Col, Input, Modal, Row, Table, Tag, Tooltip } from "antd";
import "./CallDetails.css";
import { CheckCircleOutlined, PhoneOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { CiMicrophoneOff } from "react-icons/ci";
import { CiPause1 } from "react-icons/ci";
import { IoMdKeypad } from "react-icons/io";
import { FcCallTransfer } from "react-icons/fc";
import { CiMicrophoneOn } from "react-icons/ci";
import { FaPauseCircle } from "react-icons/fa";
import { MdCallEnd } from "react-icons/md";
import { Empty } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import { useGetEnquiryListWithIdName } from "../../Utilities/tanStackQueries";
import CallFollwDateModel from "./CallFollwDateModel";
interface DataType {
  key: React.Key;
  id?: string;
  name: string;
  phone: number;
  call: string;
}
const RingModel: React.FC<{
  ringModelOpen: boolean;
  setRingModelOpen: any;
  data: any;
  setIsCallEnded: any;
}> = (props) => {
  //console.log(props);
  const [mute, setMute] = React.useState<boolean>(false);
  const [hold, setHold] = React.useState<boolean>(false);
  const [callTransfer, setCallTaransfer] = React.useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [time, setTime] = useState<any>("00:00:00");
  
  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record, index: number) => (
        <>
          <div style={{ cursor: "pointer" }}>
            <span style={{ fontWeight: "bold" }}>{record?.name}</span>
            <br />
            <span style={{ color: "gray" }}>{record?.phone}</span>
          </div>
        </>
      ),
      width: "70%",
    },
    
    {
      title: "",
      dataIndex: "call",
      // width: "4%",
      render: (_, record) => (
        <div style={{ color: "rgb(16, 124, 16)" }}>
          {record.call}
          <Tag color="default" style={{ cursor: "pointer" }}>
            Transfer
          </Tag>
        </div>
      ),
    },
  ];

  const { data: EnquiryListWithIdName, isLoading: isLoading } =
    useGetEnquiryListWithIdName(callTransfer, search);

  // callTime Function
  let seconds = 0
  function formatTime(seconds: any) {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hrs}:${mins}:${secs}`;
  }
  function start() {
    setInterval(function () {
      setTime(formatTime(seconds));
      seconds++
    }, 1000)
  }
  useEffect(() => {
    start();
  }, [])

  return (
    <>
      <Modal
        className="height"
        mask={false}
        closable={false}
        open={props.ringModelOpen}
        width={300}
        onCancel={() => props.setRingModelOpen(false)}
        footer={
          <Row
            justify={"space-evenly"}
            style={{
              textAlign: "center",
              backgroundColor: "#EEEEEE",
              padding: "5px",
            }}
          >
            <Col lg={4}>
              <Tooltip placement="top" title={'Call Transfer'}>
                <FcCallTransfer
                  size={22}
                  style={{ paddingTop: "2px", cursor: "pointer" }}
                  onClick={() => setCallTaransfer(!callTransfer)}
                />
              </Tooltip>
            </Col>
            <Tooltip placement="top" title={'Hold'}>
              <Col lg={4}>
                {hold ? (
                  <FaPauseCircle
                    size={22}
                    style={{ paddingTop: "2px", cursor: "pointer" }}
                    onClick={() => setHold(!hold)}
                  />
                ) : (
                  <CiPause1
                    size={22}
                    style={{ paddingTop: "2px", cursor: "pointer" }}
                    onClick={() => setHold(!hold)}
                  />
                )}
              </Col>
            </Tooltip>
            <Tooltip placement="top" title={'Mute/UnMute'}>
              <Col lg={4}>
                {mute ? (
                  <CiMicrophoneOff
                    size={22}
                    style={{ paddingTop: "2px", cursor: "pointer" }}
                    onClick={() => {
                      setMute(!mute);
                      // console.log(mute);
                    }}
                  />
                ) : (
                  <CiMicrophoneOn
                    size={22}
                    style={{ paddingTop: "2px", cursor: "pointer" }}
                    onClick={() => {
                      setMute(!mute);
                      //  console.log(mute);
                    }}
                  />
                )}
              </Col>
            </Tooltip>
            {/* <Col lg={4}>
              <IoMdKeypad
                size={22}
                style={{ paddingTop: "2px", cursor: "pointer" }}
              />
            </Col> */}
            <Col lg={4}>
              <Tooltip placement="top" title={'End Call'}>
                <MdCallEnd
                  size={22}
                  color="red"
                  style={{ paddingTop: "2px", cursor: "pointer" }}
                  onClick={
                    () => {
                      props.setIsCallEnded(true);
                      props.setRingModelOpen(false);
                    }
                  }
                />
              </Tooltip>
            </Col>

          </Row>
        }
        style={{ top: "45%", left: "41%" }}
      >
        <Row style={{ backgroundColor: "rgb(223, 246, 221)" }}>
          <Col lg={4}>
            <Avatar
              style={{
                marginTop: 5,
                marginLeft: 4,
                backgroundColor: "#e942f5",
                color: "#fff",
              }}
              size={35}
              icon={<UserOutlined />}
            />
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bold" }}>{props.data?.name} </p>
            <p style={{ fontSize: 14, color: "gray", textAlign: "start" }}>
              +91{props?.data?.phone}
            </p>
            <p id="callTime" style={{ textAlign: 'start', color: 'gray' }}>{time}</p>
          </Col>

        </Row>

        {callTransfer ? (
          <div
            style={{
              height: "305px",
            }}
          >
            <Input
              placeholder="Type a Name or Number Here..."
              suffix={<SearchOutlined />}
              allowClear
              style={{ marginTop: "5px" }}
              onChange={(e) => {
                // const searchItem = setTimeout(() => {
                setSearch(e.target.value);
                // }, 2000);
                // return () => clearTimeout(searchItem);
              }}
            />
            <Table
              columns={columns}
              dataSource={EnquiryListWithIdName?.result?.items}
              loading={isLoading}
              pagination={false}
              style={{ paddingTop: 5 }}
              scroll={{ y: 236 }}
            />
          </div>
        ) : (
          <div
            style={{
              height: "306px",
            }}
          >
            <Empty
              image={
                <UserOutlined style={{ fontSize: 80, color: "#bfbfbf" }} />
              }
              description=""
              style={{ paddingTop: "70px" }}
            />
            <div style={{ backgroundColor: "rgb(223, 246, 221)", marginTop: '110px', padding: '4px' }} >
              <CheckCircleOutlined style={{ marginLeft: '4px', color: 'green' }} />
              <span style={{ paddingLeft: '4px' }}>Ringing....</span>
            </div>
          </div>

        )}


      </Modal>

    </>
  );
};

export default RingModel;
