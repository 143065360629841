import { LoadingOutlined } from "@ant-design/icons";
import { Col, Radio, RadioChangeEvent, Row, Spin } from "antd";
import { useState } from "react";
import AttSheet from "./AttSheet/AttSheet";
import AttTableCalender from "./AttTableCalender/AttTableCalender";


const Attendance = () => {
    const optionsWithDisabled = [
        { label: 'Sheet', value: 'sheet' },
        { label: 'Table', value: 'table' },
        { label: 'Calender', value: 'calender' }
    ];
    const [pageName, setPageName] = useState("table");

    const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
        setPageName(value);
    };
    return (
        <Spin
            spinning={false}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
            <Col span={24 }>
                <Row justify="end" className="mb-2">
                    <Radio.Group
                        className="py-1"
                        options={optionsWithDisabled}
                        onChange={onChange4}
                        value={pageName}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Row>
            </Col>
           
            <Row>
                <Col span={24} className="att-cal-table-container">
                    {pageName === "sheet" && <AttSheet />}
                    {(pageName === "table" || pageName === "calender") && <AttTableCalender type={pageName} />}
                </Col>

            </Row>
        </Spin>
    );
};
export default Attendance;
