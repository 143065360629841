import * as React from "react";
import { useContext, useState, useEffect } from "react";
import type { TableColumnsType } from "antd";
import { Button, ConfigProvider, Select, Spin, Table } from "antd";
import Search from "antd/es/input/Search";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { pageSizeOption } from "../Users/UserList";
import HTTPSCalls from "../../Services/HTTPCalls";
import dayjs from "dayjs";
import '../EnquiryLeadModel/EnquiryLead.css'
import { useEnquiryCommunicationGetById } from "../../Utilities/tanStackQueries";
import MailModel from "../EnquiryLeadModel/MailModel";
interface MailDataType {
  key: string;
  subject: string;
  toMail: string;
  formMail: string;
  date: any;
}
const MailTable: React.FC<{
  type: number;
  apiData: any;
  mailContentRefresh:boolean;
}> = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [enquiryId,setEnquiryId]= useState<any>();
  const [modelOpen,setModelOpen]= useState<boolean>(false)
  const [lodading, setLoading] = useState<boolean>(false);
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const tableLoading = {
    spinning: lodading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  console.log("props.apiData = >>>", props.apiData);

  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [approveRequestList, setApproveRequestList]: any = useState([]);
  const [showIsApproveAll, setShowIsApproveAll] = useState(false);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [listParams, setListParams] = useState<any>({
    EnquiryId: props?.apiData?.id,
    type: props?.type,
    start: 0,
    length: 10,
    search: "",
    sortCol: "",
    sortDir: "",
    note:"",
    phoneNo:"",
    callType:0,
    outComeType:0,
    subject:"",
    toMail:"",
    title:"",
    meetingType:0,
    meetingAddress:"",
    taskType:""
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  //console.log(props,"mailprops");
  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  changeListParams(inputName, value);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  changeListParams(inputName);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
              // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className="CitSearchInput"
                allowClear
                onChange={(e) => changeListParams(inputName, e.target?.value)}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };

  const columns: TableColumnsType<MailDataType> = [
    {
      title: (
        <THeader
          title={"Subject"}
          inputName={"subject"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subject",
      key: "",
      width: "30%",
    },
    {
      title: (
        <THeader
          title={"TO"}
          inputName={"toMail"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "toMail",
      key: "",
      width: "20%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "",
      width: "15%",
      //  sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "10%",
      render: (_: any, record: any) => {
        return (
          <>
            <Button
              size="small"
              type="link"
              // trigger={["click"]}
              // menu={menuProps}
              onClick={() => {
                setEnquiryId(record?.id)
                setModelOpen(true)
                console.log("record",record);
              }}
            >
              Preview
            </Button>
          </>
        );
      },
    },
  ];
  
  const rowSelection = {
    selectedRowKeys: checkedKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setCheckedKeys(selectedRowKeys);
      let arr: any = [];
      // eslint-disable-next-line array-callback-return
      selectedRows.map((item: any) => {
        arr.push(item);
      });
      setApproveRequestList([...arr]);
      selectedRows.length > 0
        ? setShowIsApproveAll(true)
        : setShowIsApproveAll(false);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === 1 || record.status === 3, // Column configuration not to be checked
      status: record.status,
    }),
  };

  const getList = async () => {
    setLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.EnquiryCommunication_GETALL + "?",
      {
        EnquiryId: listParams?.EnquiryId,
        type: listParams?.type,
        start: listParams?.start,
        length: listParams?.length,
        search: listParams?.search,
        sortCol: listParams?.sortCol,
        sortDir: listParams?.sortDir,
        note: listParams?.note,
        phoneNo:listParams?.phoneNo,
        callType:listParams?.callType,
        outComeType:listParams?.outComeType,
        subject:listParams?.subject,
        toMail:listParams?.toMail,
        title:listParams?.title,
        meetingType:listParams?.meetingType,
        meetingAddress:listParams?.meetingAddress,
        taskType:listParams?.taskType
      }
    );
    setTotalNoOfRecords(res?.result?.totalRecords);
    setDataSource(
      res?.result?.map((itm: any, indx: number) => ({
        ...itm,
        key: indx,
        subject: itm?.subject,
        toMail: itm?.toMail,
        formMail: itm?.formMail,
        date: dayjs(itm?.createdBy?.date).format("DD MMM YYYY"),
      }))
    );

    if (res?.status) {
      setLoading(false);
    } else {
      setLoading(false);
    }
    console.log(res, "listAllMail");
  };

  React.useEffect(() => {
    getList();
  }, [props?.mailContentRefresh,refresh]);

  const onCancel=()=>{

  }


  return (
    <>
    
      <Table
       className={"Tabel-style"}
        columns={columns}
        dataSource={dataSource}
        size="small"
        loading={tableLoading}
        // rowSelection={rowSelection}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            changeListParams("start", (page - 1) * pageSize);
            changeListParams("length", pageSize);
            setRefresh((x) => !x);
          },
          current: listParams.start / listParams.length + 1,
          pageSize: listParams.length,
          showSizeChanger: true,
          total: totalNoOfRecords,
          pageSizeOptions: pageSizeOption,
          position: ["bottomRight"],
        }}
        scroll={{ y: `calc(100vh - 330px)` }}
      />
      
      <MailModel open={modelOpen} id={enquiryId} type={3} onCancel={onCancel}/>
    </>
  );
};

export default MailTable;
