import { Card, Col, Empty, Image, Row, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { CenterVisited, IndianState, languageProficiency, outcomeType, StudyModeObj } from '../../Constant/Enums'
import Meta from 'antd/es/card/Meta'
const { Text, Link } = Typography;
interface IViewStudentDetails {
    apiData: any
}
const ViewStudentDetails = (props: IViewStudentDetails) => {
    const { apiData } = props
    console.log(apiData, "78");

    return (
        <>
            <div style={{ maxHeight: `calc(100vh - 220px)`, overflowY: "auto" }}>
                <Row gutter={[24, 16]}>
                    <Col span={24}>
                        <Card bordered size='small' title={
                            <>
                                <Meta style={{ fontSize: 12 }} title={
                                    <Space size={10}>
                                        <span style={{ fontSize: 14, color: "#4c4c4c" }}>General Information</span>
                                    </Space>
                                } />
                            </>
                        }>
                            <Row>
                                <Col lg={3} style={{ color: 'gray' }}>Enquiry Date</Col>
                                <Col lg={21}>{dayjs(apiData?.enquiryDate)?.format('DD MMM YYYY')}</Col>
                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>First Name</Col>
                                <Col lg={3}>{apiData?.name}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Middle Name:</Col>
                                <Col lg={3}>{apiData?.middleName}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Last Name:</Col>
                                <Col lg={3}>{apiData?.lastName}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Date of Birth:</Col>
                                <Col lg={3}>{dayjs(apiData?.dateOfBirth)?.format('DD MMM YYYY')}</Col>
                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Gender</Col>
                                <Col lg={3}>{apiData?.gender?.name}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Nationality</Col>
                                <Col lg={3}>{apiData?.nationality}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>English Proficiency</Col>
                                <Col lg={3}> {languageProficiency[apiData?.englishProficiency]}</Col>
                                {/* <Col lg={3} style={{ color: 'gray' }}>Counselor's</Col>
                                <Col lg={3}>{apiData?.counselors}</Col> */}
                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Sources</Col>
                                <Col lg={3}>{apiData?.sources?.name}</Col>
                                {/* <Col lg={3} style={{ color: 'gray' }}>Assign To</Col>
                                <Col lg={3}>--</Col> */}
                                <Col lg={3} style={{ color: 'gray' }}>Center Visited</Col>
                                <Col lg={3}>{CenterVisited[apiData?.centerVisited]} </Col>
                                <Col lg={3} style={{ color: 'gray' }}>Status</Col>
                                <Col lg={3}>{outcomeType[apiData?.response]}</Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 16]} style={{ marginTop: 18 }}>
                    <Col span={24}>
                        <Card size='small' title={
                            <>

                                <Meta style={{ fontSize: 12 }} title={
                                    <Space size={10}>
                                        <span style={{ fontSize: 14, color: "#4c4c4c" }}>Address & Contact</span>
                                    </Space>
                                } />
                            </>
                        }>
                            <Row>
                                <Col lg={3} style={{ color: 'gray' }}>Contact Number</Col>
                                <Col lg={3}> {apiData?.contactDetail?.mobileNumber}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>City</Col>
                                <Col lg={3}>{apiData?.city}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>State</Col>
                                <Col lg={3}>
                                    {IndianState.find((x: any) => x.value === apiData?.state)?.label}
                                </Col>


                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Email</Col>
                                <Col lg={10}><Text copyable title={apiData?.contactDetail?.email} ellipsis>{apiData?.contactDetail?.email}</Text></Col>

                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Address</Col>
                                <Col lg={10}>{apiData?.contactDetail?.currentAddress}</Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: 18 }}>
                    <Col span={24}>
                        <Card size='small' title={<><Meta style={{ fontSize: 12 }} title={<Space size={10}><span style={{ fontSize: 14, color: "#4c4c4c" }}>Parents and Guardian</span></Space>} /></>}>
                            <Row>
                                <Col lg={24} style={{ color: "#4c4c4c", marginBottom: "10px", fontSize: 15, fontWeight: 600 }}></Col>
                            </Row>
                            <Row>
                                <Col lg={3} style={{ color: 'gray' }}>Father's Name</Col>
                                <Col lg={3}>{apiData?.fatherName}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Father's Occupation</Col>
                                <Col lg={3}>{apiData?.fatherOccupation}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Mother's Name</Col>
                                <Col lg={3}>{apiData?.motherName}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Mother's Occupation</Col>
                                <Col lg={3}>{apiData?.motherOccupation}</Col>
                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian's Name</Col>
                                <Col lg={3}>{apiData?.name}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian Relation</Col>
                                <Col lg={3}>{apiData?.guardianRelation}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian's Phone No</Col>
                                <Col lg={3}>{apiData?.guardianPhone}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian's Occupation</Col>
                                <Col lg={3}>{apiData?.guardianOccupation}</Col>
                            </Row>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian's Address</Col>
                                <Col lg={3}>{apiData?.guardianAddress}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Zip Code</Col>
                                <Col lg={3}>{apiData?.guardianZipCode}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian's City</Col>
                                <Col lg={3}>{apiData?.guardianCity}</Col>
                                <Col lg={3} style={{ color: 'gray' }}>Guardian's State</Col>
                                <Col lg={3}>    {
                                    IndianState.find(
                                        (state: any) => state.value === apiData?.state
                                    )?.label
                                }</Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 16]} style={{ marginTop: 18 }}>
                    <Col span={24}>
                        <Card size='small' title={
                            <>
                                <Meta style={{ fontSize: 12 }} title={
                                    <Space size={10}>
                                        <span style={{ fontSize: 14, color: "#4c4c4c" }}>Education</span>
                                    </Space>
                                } />
                            </>
                        }>

                            <Row gutter={[24, 16]}>
                                <Col span={4} style={{ color: 'gray' }}>School/Collage Name</Col>
                                <Col span={3} style={{ color: 'gray' }}>Grade/Graduate</Col>
                                <Col span={3} style={{ color: 'gray' }}>Year</Col>
                                <Col span={3} style={{ color: 'gray' }}>Percent</Col>
                                <Col span={3} style={{ color: 'gray' }}>Study Mode</Col>
                                <Col span={4} style={{ color: 'gray' }}>Curriculum</Col>
                                <Col span={3} style={{ color: 'gray' }}>Stream</Col>
                            </Row>

                            {apiData?.academicRecord?.map((itm: any, indx: number) => (
                                <Row key={indx} gutter={[24, 16]}>
                                    <Col span={4}>{itm?.schoolName}</Col>
                                    <Col span={3}>{itm?.class?.name}</Col>
                                    <Col span={3}>{itm?.year}</Col>
                                    <Col span={3}>{itm?.percentage}</Col>
                                    <Col span={3}>{StudyModeObj[itm?.studyMode]}</Col>
                                    <Col span={4}>{itm?.curriculum?.name}</Col>
                                    <Col span={3}>{itm?.stream?.name}</Col>
                                </Row>))}
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: 18 }} >
                    <Col span={24}>
                        <Card size='small' title={<><Meta style={{ fontSize: 12 }} title={<> <span style={{ fontSize: 14, color: "#4c4c4c" }}>Upload Documents</span></>} /></>}>
                            <Row style={{ marginTop: 18 }} gutter={[24, 16]}>
                                <Col lg={6}>
                                    <Card size='small' title={"Photo"} bordered={false}>
                                        <Space align='center' direction={"horizontal"} >
                                            {
                                                apiData?.candidateImage?.path ?
                                                    <img width={184} src={apiData?.candidateImage?.path} />
                                                    :
                                                    <>
                                                        <Empty />
                                                    </>
                                            }
                                        </Space>
                                    </Card>

                                </Col>
                                <Col lg={6}>
                                    <Card size='small' title={"Signature"} bordered={false}>
                                        {
                                            apiData?.signature?.candidate?.path ?
                                                <img width={184} src={apiData?.signature?.candidate?.path} />
                                                :
                                                <>
                                                    <Empty />
                                                </>
                                        }

                                    </Card>

                                </Col>
                                <Col lg={6}>
                                    <Card size='small' title={"Counselor Signature"} bordered={false}>
                                        {
                                            apiData?.signature?.counselor?.path ?
                                                <img width={184} src={apiData?.signature?.counselor?.path} />
                                                :
                                                <>
                                                    <Empty />
                                                </>
                                        }

                                    </Card>

                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: 18 }}><Col span={24}><Card size='small' title={<><Meta style={{ fontSize: 12 }} title={<span style={{ fontSize: 14, color: "#4c4c4c" }}>Required Information</span>}></Meta></>}>
                    <Row style={{ marginTop: 18 }}>
                        <Col lg={2} style={{ color: 'gray' }}>Total Fees</Col>
                        <Col lg={2}>{apiData?.amount}</Col>
                        <Col lg={2} style={{ color: 'gray' }}>Student ID</Col>
                        <Col lg={2}>{apiData?.studentId}</Col>
                        <Col lg={2} style={{ color: 'gray' }}>Card No.</Col>
                        <Col lg={2}>{apiData?.cardNo}</Col>
                        <Col lg={2} style={{ color: 'gray' }}>Biometric Code</Col>
                        <Col lg={2}>{apiData?.biometricCode}</Col>
                        <Col lg={2} style={{ color: 'gray' }}>Enrolled Date</Col>
                        <Col lg={2}>{dayjs(apiData?.enrolledDate)?.format("DD-MMM-YYYY")}</Col>
                        <Col lg={2} style={{ color: 'gray' }}>Batch</Col>
                        <Col lg={2}>{apiData?.batch?.name}</Col>
                    </Row>
                </Card></Col></Row>


                <Row style={{ marginTop: 18 }}>
                    <Col span={24}>
                        <Card size='small' title={<><Meta style={{ fontSize: 12 }} title={<> <span style={{ fontSize: 14, color: "#4c4c4c" }}>Other Information</span></>} /></>}>
                            <Row style={{ marginTop: 18 }}>
                                <Col lg={3} style={{ color: 'gray' }}>Other Information</Col>
                                <Col lg={8}>{apiData?.otherInformation ?? "---"}</Col>
                                <Col lg={6} style={{ color: 'gray' }}>Referral Source / Where did you hear about us?</Col>
                                <Col lg={7}>{apiData?.referralSource ?? "---"}</Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ViewStudentDetails
