import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import React, { useState } from "react";
import { OutcomeType, outcomeTypeOptions } from "../../Constant/Enums";
import DNCModel from "../EnquiryLeadModel/DNCModel";
import type { RadioChangeEvent } from 'antd';

const CallFollwDateModel: React.FC<{
  isOpen: boolean;
  setIsCallEnded: any;
  data: any;
}> = (props) => {
  const [form] = Form.useForm();
  const [dncModelOpen, setDncModelOpen] = React.useState<boolean>(false);
  const [radioButtonValue, setRadioButtonValue] = useState<string>("followDate");

  const onChange = (e: RadioChangeEvent) => {
   // console.log('radio checked', e.target.value);
    setRadioButtonValue(e.target.value);
  };
  
  const onSave = () => {
    form.validateFields().then((values:any) => {
      console.log(values,"values");
      props.setIsCallEnded(false);
    }).catch((err:any) => {
      console.log(err,"error");
    })
  }
  return (
    <>
      <Modal
        className="heightModel"
        mask={false}
        closable={false}
        open={props.isOpen}
        width={300}
        onCancel={() => props.setIsCallEnded(false)}
        footer={
          <Row justify="space-evenly">
            <Button
              onClick={() => props.setIsCallEnded(false)}
              // disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              className="ttp-btn-light btn-m-sm"
              // loading={btnLoading}
              type="primary"
              onClick={onSave}
            >
              Save
            </Button>
          </Row>
        }
        style={{ top: "45%", left: "41%" }}
      >
        <Row style={{ backgroundColor: "rgb(223, 246, 221)", padding: "0px" }}>
          <Col lg={4}>
            <Avatar
              style={{
                marginTop: 5,
                marginLeft: 4,
                backgroundColor: "#e942f5",
                color: "#fff",
              }}
              size={35}
              icon={<UserOutlined />}
            />
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bold" }}>{props.data?.name} </p>
            <p style={{ fontSize: 14, color: "gray", textAlign: "start" }}>
              +91{props?.data?.phone}
            </p>
          </Col>
        </Row>

        <div
          style={{
            height: "338px",
            marginTop: "10px",
          }}
        >
          <Form requiredMark={false} colon={false} form={form}>
            <Form.Item>
              <Radio.Group value={radioButtonValue} onChange={onChange}>
                <Radio
                  value="followDate"
                  onChange={(e) => {
                    console.log(e, "test");
                  }}
                >
                  Set Next Follow Date{" "}
                </Radio>
                <Radio
                  value="leadLost"
                  onChange={(e) => {
                    console.log(e, "test");
                  }}
                >
                  {" "}
                  Lead Lost{" "}
                </Radio>
              </Radio.Group>
            </Form.Item>

           {radioButtonValue === "followDate" && (
             <Form.Item name="date" label="Date" className="marginbtm">
             <DatePicker style={{ width: "100%" }} format={'DD-MMM-YYYY'} />
           </Form.Item>   
           )}

            <Form.Item name="outcomeType" label="Type" className="marginbtm">
              <Select
                placeholder="Choose Outcome"
                options={outcomeTypeOptions}
                style={{ width: "100%" }}
                onSelect={(e: any) => {
                  if (e === OutcomeType.DNC) {
                    setDncModelOpen(true);
                  }
                }}
              />
            </Form.Item>

            <Form.Item name="note" label="Note" className="marginbtm">
              <Input.TextArea
                rows={4}
                showCount
                placeholder="Add Note Here....."
                maxLength={100}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {dncModelOpen && (
        <DNCModel
          open={dncModelOpen}
          text={"Are you sure you want to add this number in DNC ?"}
          onOk={() => setDncModelOpen(false)}
          onCancel={() => setDncModelOpen(false)}
        />
      )}
    </>
  );
};

export default CallFollwDateModel;
