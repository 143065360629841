import { useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message, Select, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ArrowLeftOutlined, DeleteOutlined, FormOutlined, LoadingOutlined, PlusOutlined, UndoOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { MasterTypes, RecordStatusType } from "../../../Constant/Enums";
import { useNavigate } from "react-router";
import AddEditSubjectTypes from "./AddEditSubjectTypes";
import { SubjectTypeMaster } from "../../../ApiUrls/URLS";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { UseSubjectTypes } from "../../../Utilities/tanStackQueries";

const api = new ApiCalls(SubjectTypeMaster.endPoints, SubjectTypeMaster.prefix);
interface I_SUBJECT {
  key: string;
  sNo: number;
  id: string;
  name: string;
}

const SubjectTypes = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<I_SUBJECT> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setsubjectId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },

    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd" }}
            onClick={() => {
              setsubjectId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
    {
      title: "",
      width: "5%",
      render: (_: any, record: any) => (
        <>
        {console.log("record",record)}
        
        <Row justify="end">
          {record?.recordStatus  === RecordStatusType?.Deleted  ? (
            <UndoOutlined
              className="ca-edit-btn me-2"
              style={{ color: "#a5abdd", cursor: "pointer" }}
              onClick={() => UpdateRecordStatus(record?.id, "restore")}
            />
          ) : (
            // changedStatus !== "all" && (
            
              <DeleteOutlined
                className="ca-edit-btn me-2"
                style={{ color: "#a5abdd", cursor: "pointer" }}
                onClick={() => UpdateRecordStatus(record?.id, "delete")}
              />
            // )
            
          )}
        </Row>
        </>
      ),
    }
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [subjectId, setsubjectId] = useState<string | undefined>();
  const [subjectRecId, setSubRecId] = useState<any>()
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allSubject, setAllSubject] = useState<I_SUBJECT[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [changedStatus,setChangedStatus]=useState<any>('active');
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
    status:"active"
  });

  const { data, isLoading, isError } = UseSubjectTypes({
    masterType: MasterTypes.SubjectTypes,
    start: (pagingItems.currentPage - 1) * pagingItems.pageSize,
    length: pagingItems.pageSize,
    search: listParams.search,
    sortCol: listParams.sortCol,
    sortDir: listParams.sortDir,
    status:changedStatus
  },refresh);

  useEffect(() => {
    if (!isLoading && !isError && data?.result) {
      setPagingItems((p) => {
        p.totalRecords = data.result.totalRecords;
        return p;
      });
      
      setAllSubject(
        data.result.items.map((r: any, i: any) => ({
          key: i,
          sno: i+1,
          id: r?.id,
          name: r?.name,
          recordStatus:r?.recordStatus
        }))
      );
    }

    if (isError) {
      message.error("Failed to load subjects.");
    }
  }, [data]);

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1 });
    setListParams({ ...listParams, search: value });
    // setChangedStatus(status); // Update the status and trigger a data refresh
  };
  
  useEffect(() => {
    if (subjectRecId) {
      UpdateRecordStatus();
    }
  }, [subjectRecId]);

  const UpdateRecordStatus = async (subjectId?: string, action: string = "delete") => {
    try {
      if (!subjectId) {
        console.error("Subject ID is required to update record status.");
        message.error("Subject ID is missing. Unable to update record status.");
        return;
      }
  
      setPageLoader(true);
  
      // console.log(`Attempting to ${action} record with ID: ${subjectId}`);
      const res: any = await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}?objId=${subjectId}&restore=${action}`,
        {},{}
      );
      setRefresh((prev) => !prev);
      // console.log("API Response:", res);
  
      // if (res?.status) {
      //   if (action === "delete") {
      //     setAllSubject((prevSubjects) => {
      //       if (!prevSubjects) {
      //         console.error("Previous subjects list is undefined.");
      //         return [];
      //       }
  
      //       const updatedSubjects = prevSubjects.filter(
      //         (subject) => subject.id !== subjectId
      //       );
  
      //       return updatedSubjects.map((subject, index) => ({
      //         ...subject,
      //         sno: index + 1, // Recalculate serial numbers
      //       }));
      //     });
  
      //     setPagingItems((prev) => ({
      //       ...prev,
      //       totalRecords: Math.max(prev.totalRecords - 1, 0),
      //     }));
  
      //     message.success("Record Deleted Successfully");
      //   } else if (action === "restore") {
      //     message.success("Record Restored Successfully");
      //   }
  
      //   setRefresh((prev) => !prev);
      // } else {
      //   message.error("An error occurred while updating the record status.");
      //   console.error("API Errors:", res?.errors);
      // }
    } catch (error) {
      message.error("An unexpected error occurred.");
      console.error("Error during API call:", error);
    } finally {
      setPageLoader(false);
    }
  };
  return (
    <>
      <AddEditSubjectTypes
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        subjectId={subjectId}
        SetDrawerClose={setOpenDrawer}
      />
      <Row justify={'space-between'} align="middle" style={{ marginBottom: "6px" }}>
        <ArrowLeftOutlined style={{ color: '#1677ff' }} onClick={() => {
          navigate('/settings')
        }} />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "SubjectTypes",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row>
            <Col lg={18}>
              <Button
                type="primary"
                className="cit-add-btn mb-1"
                onClick={() => {
                  setsubjectId(undefined);
                  setOpenDrawer(true);
                }}
              >
                <PlusOutlined />
                Subject Types
              </Button>
            </Col>
            <Col lg={3}>
              <Select
                showSearch
                style={{ width: 185 }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val)=>setChangedStatus(val)}
                defaultValue={'active'}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'inactive',
                    label: 'Inactive',
                  },
                  {
                    value: 'active',
                    label: 'Active',
                  }
                ]}
              />
            </Col>
            <Col lg={3}>
              <Search
                size="middle"
                placeholder="Search subject types"
                allowClear
                className="att-search-input mb-1"
                onSearch={(val: string) => setListParamsAndRefresh(val)}
                onChange={(e: any) =>
                  e.target.value === "" ? setListParamsAndRefresh("") : null
                }
                style={{ width: 210 }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader || isLoading,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allSubject}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SubjectTypes;
