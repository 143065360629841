import { combineReducers, configureStore } from "@reduxjs/toolkit";
import studentAttSlice from "./Slice/studentAttSlice";
import batchSlice from "./Slice/batchSlice";


const rootReducer = combineReducers({
  student: studentAttSlice,
  batch:batchSlice
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware:any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
