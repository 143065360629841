import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import "../../../Common/Common.css";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import AttendanceService from "../../../Services/AttendanceService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { BatchMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { useForm } from "antd/es/form/Form";
const masterApi = new ApiCalls(BatchMaster.endPoints, BatchMaster.prefix);
const { Option } = Select;
interface IProps {
  openDrawer: boolean;
  drawerClose: Function;
  setTrigger: Function;
}
const MarkAttendanceBulk = ({
  openDrawer,
  drawerClose,
  setTrigger,
}: IProps) => {
  const columns: ColumnsType<any> = [
    {
      key: "name",
      title: "Student Name",
      dataIndex: "fullName",
      width: "70%",
      render: (value: any, record: any, index: number) => (
        <span
          onClick={() => {
            setModelOpen(true);
            setData(record);
            // setHolidayId(record?.id);
            // setOpenDrawer(true);
            
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
          >
          {value } -  <span style={{ color: "gray", fontSize: 12 }}>
              {record?.studentId || ""}
            </span>
        </span>
      ),
      // render: (value, record, index) => {
      //   return (
      //     <>
      //       <Button
      //         onClick={() => {
      //           console.log(value, record, index);
      //         }}
      //       >
      //         {value}
      //       </Button>
      //     </>
      //   );
      // },
    },
    {
      key: "attendance",
      title: "Attendance",
      dataIndex: "attendance",
      width: "30%",
      render: (_, record) => (
        <div>
          <Tag
            color={record?.status === 0 ? "error" : "success"}
            style={{
              fontWeight: 500,
              fontSize: 12,
              padding: "0px 25px",
            }}
          >
            {record?.status === 0 ? "Absent" : "Present"}
          </Tag>
        </div>
      ),
    },
  ];
  const [form] = useForm();
  const { openNotification } = useContext(adminContext);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [batchOptions, setBatchOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [drawerLoader, setIsDrawerLoader] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [data, setData] = useState<any>({});
  const [tableLoader, setTableLoader] = useState(false);
  const [batchLoader, setBatchLoader] = useState(false);
  const [tableAttendanceData, setTableAttendanceData] = useState<any>([]);
  const [date, setDate] = useState<string>(dayjs(dayjs()).format("MM-DD-YYYY"));
  const [batchId, setBatchId] = useState<string>("");
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (openDrawer) {
      setIsDrawerLoader(true);
      getBatchOptions();
    }
  }, [openDrawer]);
  useEffect(() => {
    if (openDrawer) {
      if (date && batchId) {
        getAttendanceData(
          (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
          pagingItems?.pageSize
        );
      }
    }
  }, [batchId, date, pagingItems,refresh]);
  useEffect(() => {
    if (!tableLoader && !batchLoader) {
      setIsDrawerLoader(false);
    }
  }, [batchLoader, tableLoader]);
  const getBatchOptions = async () => {
    setBatchLoader(true);
    let res = await masterApi.GET(BatchMaster.endPoints.getList + "?", {
      masterType: MasterTypes.Batch,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    setBatchOptions(
      res?.result?.items?.map((opt: { id: string; name: string }) => {
        return {
          value: opt?.id,
          label: opt?.name,
        };
      })
    );
    setBatchLoader(false);
  };
  const getAttendanceData = async (start: any, length: any) => {
    if (date) {
      let params = {
        date,
        batchId,
        start,
        length,
      };
      setTableLoader(true);
      let res: any = await AttendanceService.get_all_att_by_batch_and_date(
        params
      );
      if (res?.items?.length > 0) {
        console.log("result",res?.items);
        
        setPagingItems((p) => {
          p.totalRecords = res?.totalRecords;
          return p;
        });
        setTableAttendanceData(() =>
          res?.items?.map((itm: any, index: number) => {
            return {
              id: itm?.id,
              key: index,
              fullName:itm?.fullName,
              studentId:itm?.studentId,
              name: itm?.name,
              status: itm?.status,
            };
          })
        );
      } else {
        setPagingItems((p) => {
          p.totalRecords = 0;
          return p;
        });
        setTableAttendanceData([]);
      }
      setTableLoader(false);
    }
  };
  const OnSave = async () => {
    if (batchId && date) {
      setSaveBtnLoader(true);
      let finalVal = {
        entryDate: date,
        batchId: batchId,
        students: tableAttendanceData?.map((itm: any) => {
          return {
            student: {
              id: itm?.id,
              name: itm?.name,
            },
            type: itm?.status,
          };
        }),
      };
      let res = await AttendanceService.postBulkAtt(finalVal);
      if (res.status) {
        openNotification("success", "Attendance saved successfully");
        setTrigger((x: boolean) => !x);
      } else {
        openNotification("error", res?.message);
      }
      setSaveBtnLoader(false);
    } else {
      openNotification("error", "Please select valid batch");
    }
  };
  const closeDrawer = () => {
    setTableAttendanceData([]);
    drawerClose();
    setBatchId("");
    setDate(dayjs(dayjs()).format("MM-DD-YYYY"));
  };
  const getSelectedRow = () => {
    let selectedRow: any = [];
    if (tableAttendanceData) {
      let selectRow = tableAttendanceData?.map((itm: any, index: number) => {
        if (itm.status === 1) {
          selectedRow.push(index);
        }
        return itm;
      });
    }
    return selectedRow;
  };
  return (
    <Drawer
      title="Mark Attendance"
      width={600}
      open={openDrawer}
      onClose={closeDrawer}
      maskClosable={false}
      closeIcon={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          className="curser"
          onClick={closeDrawer}
        />
      }
    >
      {/* <Spin
        spinning={drawerLoader}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      > */}
      <Row gutter={[26, 26]} className="px-2 py-3">
        <Col lg={24}>
          <Row gutter={[14, 14]}>
            <Col span={6}>
              <DatePicker
                disabledDate={(current) => {
                  return current && current > dayjs().endOf("day");
                }}
                format="DD/MM/YYYY"
                defaultValue={dayjs()}
                style={{ width: "100%" }}
                onChange={(val) => setDate(dayjs(val)?.format("MM-DD-YYYY"))}
              />
            </Col>
            <Col span={6}>
              <Select
                popupClassName="cit-select-box"
                value={batchId}
                onSelect={(val) => {
                  setBatchId(val);
                }}
                placeholder="Select Batch"
                allowClear
                listHeight={224}
                showSearch
                style={{ width: "100%" }}
                options={batchOptions}
              ></Select>
            </Col>
          </Row>
        </Col>
        <Col lg={24}>
          <Table
            dataSource={tableAttendanceData}
            columns={columns}
            footer={() => (
              <Row justify="end">
                <Button
                  disabled={tableAttendanceData.length > 0 ? false : true}
                  loading={saveBtnLoader}
                  onClick={() => OnSave()}
                >
                  Save
                </Button>
              </Row>
            )}
            loading={{
              spinning: tableLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            rowSelection={{
              selectedRowKeys: getSelectedRow(),
              preserveSelectedRowKeys: true,
              onSelect: (record, selected, selectedRows, nativeEvent) => {
                setTableAttendanceData(
                  tableAttendanceData.map((x: any) => {
                    if (x?.id === record?.id) {
                      return {
                        fullName: x.fullName,
                        key: x.key,
                        id: x.id,
                        status: selected ? 1 : 0,
                        studentId:x.studentId,
                      };
                    } else {
                      return x;
                    }
                  })
                );
              },
              onSelectAll: (selected, selectedRows, changeRows) => {
                setTableAttendanceData(
                  tableAttendanceData.map((x: any) => {
                    return {
                      fullName: x.fullName,
                      key: x.key,
                      id: x.id,
                      studentId:x.studentId,
                      status: selected ? 1 : 0,
                    };
                  })
                );
              },
              getCheckboxProps: (record: any) => {
                return {
                  disabled: record.name === "Disabled User", //Column configuration not to be checked.
                  name: record.name,
                };
              },
            }}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: [15, 20, 30, 50],
              position: ["bottomRight"],
            }}
            onChange={(paging, filter, sort: any) => {
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 0,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
      {/* </Spin> */}
      {/* <Modal open={modelOpen}>
        <Card>{JSON.stringify(data)}</Card>
      </Modal> */}
      <Drawer
        title={data.name}
        width={400}
        closable={false}
        onClose={() => {
          setModelOpen(false);
        }}
        open={modelOpen}
        // style={{ padding: "24px 14px" }}
        extra={
          <Button
            type="primary"
            ghost
            onClick={() => {
              form.validateFields().then(async (values: any) => {
                // console.log(values, "val");
                // console.log(data, "data");
                // console.log(date, "date");
                // console.log(batchId, "batchId");

                if (batchId && date) {
                  // setSaveBtnLoader(true);
                  let finalVal = {
                    entryDate: date,
                    batchId: batchId,
                    students: [
                      {
                        student: {
                          id: data?.id,
                          name: data?.name,
                        },
                        type: values?.att,
                      },
                    ],
                  };
                  let res = await AttendanceService.postBulkAtt(finalVal);
                  if (res.status) {
                    openNotification(
                      "success",
                      "Attendance saved successfully"
                    );
                    setRefresh(!refresh);
                    setModelOpen(false);
                    setTrigger((x: boolean) => !x);
                  } else {
                    openNotification("error", res?.message);
                  }
                  // setSaveBtnLoader(false);
                } else {
                  openNotification("error", "Please select valid batch");
                }
              });
            }}
          >
            Save
          </Button>
        }
      >
        {/* <Row> */}
        <Form
          form={form}
          labelAlign="left"
          labelCol={{ span: 12 }}
          style={{ padding: "14px 14px" }}
          // wrapperCol={{ span: 18 }}
        >
          {/* <Form.Item label="Select Date" name={"date"}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item> */}
          <Form.Item label="Select Attendance" name={"att"}>
            <Select
              options={[
                { label: "Absent", value: 0 },
                { label: "Present", value: 1 },
              ]}
            ></Select>
          </Form.Item>
        </Form>
        {/* </Row> */}
        {/* {JSON.stringify(data)} */}
      </Drawer>
    </Drawer>
  );
};
export default MarkAttendanceBulk;
