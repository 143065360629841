import { ApiUtility } from "../Utilities/ApiUtility.axios";

// import {}  from "process"
class HTTPSCalls {
  static ENDPOINTS = {
    ENQUIRY_GETALL: "Enquiry/GetAll",
    ENQUIRY_GET_BY_ID: "Enquiry/GetById/",
    ENQUIRY_GETALL_INFO: "Enquiry/GetList",
    ENQUIRY_ADD: "Enquiry/Save",
    ENQUIRY_UPDATE: "Enquiry/Update",
    ENQUIRY_STUDENTS: "Enquiry/GetStudents",
    ENQUIRY_APPROVE: "Enquiry/Approve",
    ENQUIRY_UPDATE_LIST: "Enquiry/UpdateList",
    ENQUIRY_ADD_ATTACHMENTS: "Enquiry/AddAttachment",
    ENQUIRY_CSV_DOWNLOAD: "Enquiry/GetCsv",
    ENQUIRY_REJECTION: "Enquiry/RejectEnquiry",
    ENQUIRY_SET_STEPS: "Enquiry/SetStep",
    ENQUIRY_ASSIGNCARD: "Enquiry/AssignCard",
    ENQUIRY_SEND_EMAIL_INVOICE: "Enquiry/SendEmailInvoice",
    ENQUIRY_ADD_CREDENTIALS: "Enquiry/AddCredentials",
    ATTENDANCE_GETALL: "Attendance/GetAll",
    ATTENDANCE_ADD: "Attendance/Add",
    ATTENDANCE_UPDATE: "Attendance/Update",
    ATTENDANCE_ManualEntry: "Attendance/ManualEntry",
    ATTENDANCE_SHEET: "Attendance/AttendanceSheet",
    MASTERS_GETALL: "IdName/GetAll",
    MASTERS_ADD: "IdName/Add",
    MASTERS_ADD_BATCH: "Batch/Add",
    MASTERS_GET_BATCH: "Batch/GetAll",
    PAYMENT_ADD: "Payment/Add",
    PAYMENT_UPDATE: "Payment/UpdateFees",
    PAYMENT_BY_ID: "Payment/GetBy",
    PAYMENT_GETALL: "Payment/GetAll",
    PAYMENT_GET_LIST: "Payment/GetList",
    PAYMENT_GET_VIEW: "Payment/GetPdf",
    PAYMENT_DOWNLOAD: "Payment/DownloadPdf",
    PAYMENT_BOTH_DOWNLOAD: "Payment/DownloadBothPdf",
    GET_MASTER_ALL: "Masters/GetAllMasters",
    GET_MASTER_SEARCH: "Masters/Search",
    SAVE_STATUS_ACTION: "Masters/StatusAction",
    GET_USER_LIST: "Auth/UserList",
    SAVE_USER: "Auth/SaveUser",
    GET_USER_BY_ID: "Auth/GetUser",
    GET_SUBJECTS_BY_DEPARTMENT: "Masters/SubjectsByDepartment",
    GET_SEMESTERS_BY_DEPARTMENT: "Masters/SemestersByDepartment",
    GET_COURSE_BY_DEPARTMENT: "Masters/CoursesByDepartment",
    GET_SCHEMAS_LIST: "Masters/Schemas",
    APPLY_CURRENT_SCHEMAS: "Masters/CurrentSchema",
    GET_CURRENT_SCHEMA_BY_COURSE: "Masters/CurrentSchemasByCourse",
    POST_MASTER: "Masters/",
    GET_SEMESTERS_WITH_SUBJECTS: "Masters/SemesterWithSubjects",
    GET_PROCESS_STEPS: "ProcessSteps",
    POST_PROCESS_STEPS: "ProcessSteps",
    GET_PROCESS_STEP_BY_ID: "ProcessSteps/GetById",
    GetEnquiryForm: "UnauthorizedAccess/GetEnquiryForm",
    GetMasterList: "UnauthorizedAccess/GetAllMasters",
    SaveWebEnquiry: "UnauthorizedAccess/Save",
    EnquiryCommunication: "EnquiryCommunication/Save",
    EnquiryCommunication_GETBYID: "EnquiryCommunication/CommunicationGetById",
    EnquiryCommunication_GETALL: "EnquiryCommunication/GetAllList",
    EnrollForm_GET: "EnquiryCommunication/GetEnrollPdf",
    Download_View_Pdf: "EnquiryCommunication/GetDownloadView",
    GET_LOG: "UserLogs",
    GET_SESSION_LIST: "Masters/GetSessionListByCourseId",
    GET_SEMESTER_LIST: "Masters/GetSemesterListBySessionId",
    GET_SUBJECT_LIST: "Masters/GetSubjectListBySessionId",
    FEES_CSV_DOWNLOAD: "Payment/DownloadCSV",
    GET_COURSE_DETAILS: "Masters/GetCourseDetails",
    GET_FACULTY_DETAILS: "Masters/GetFacultyDetails",
    GET_SECTIONS_DETAILS: "Masters/GetSectionsDetails",
    GET_BATCH_SEARCHING: "Masters/batchSearching",
    GET_MASTER_TYPE: "Masters",
    GET_COMMON_WEIGHT: "Assessments/GetAssessmentsWeight",
    SCHEDULE_CREATE: "CreateSchedule/Save",
    SCHEDULE_GETLIST: "CreateSchedule/GetAllList",
    GET_SEMESTER_DATA: "Masters/GetSemesterData",
    GET_ECARD_LIST: "Ecard/EcardList",
    POST_ECARD: "Ecard/AddEdit",
    GET_BIOMATRIC_DEVICE_LIST: process.env?.REACT_APP_BIO_MATIRC_URL,
    GET_SUBJECT_ALLOCATION_LIST: "Masters/GetSubjectAllocationMasterDetails",
    SUBJECTALLOCATION: "SubjectAllocation/Save",
    SCHEDULE_GET_BY_ID: "CreateSchedule/GetById",
    ASSIGN_CARD: "ECard/AssignCard",
    POST_ASSESSMENTS_DATA: "Assessments/Save",
    GET_ASSESSMENTS_DATA: "Assessments/GetAssessments",
    DELETE_SCHEDULE: "CreateSchedule/DeleteSchedule",
    UPDATE_SCHEDULE: "CreateSchedule/UpdateScheduleList",
    //https://localhost:7142/API/CreateSchedule/UpdateScheduleList
    SCHEDULE_ITEM_LIST: "CreateSchedule/GetScheduleItemList",
    GET_LIST_WITH_ID_NAME: "CreateSchedule/GetListWithIdName",
    GET_ENQUIRY_LIST_WTIH_IDNAME:"Enquiry/GetListWithIdName",
    POST_ASSESSMENTS_WEIGHT:"Assessments/AssessmentsWeight",
    POST_ASSESSMENTS_WEIGHT_DETAILS:"Assessments/AssessmentsWeightWithDetail",
    UPDATE_RECORD_STATUS:"Masters/UpdateRecordStatus",
    GET_BATCH_STUDENT :"Attendance/GetAllAttByBatch",
    GET_ALL_STUDENT_ATTENDANCE :"Attendance/GetAllStudentAttendance",
    REVIVE_RECORD:"Masters/ReviveRecord",
    ENQUIRY_EXISTS:"Enquiry/Exists",
    GET_STUDENT_STATUS:"DashBoard/GetStudentStatus",
 };

  static GET = async (URL_BASE: string, URLParameters: any) => {
    return await ApiUtility.get(
      URL_BASE + new URLSearchParams({ ...URLParameters }).toString()
    );
  };

  static POST = async (
    URL_BASE: string,
    URLParameters: any = {},
    requestBody: any
  ) => {
    return await ApiUtility.post(
      URL_BASE + new URLSearchParams({ ...URLParameters }).toString(),
      requestBody
    );
  };
  static POST_FROM = async (
    URL_BASE: string,
    URLParameters: any = {},
    requestBody: any
  ) => {
    return await ApiUtility.postForm(
      URL_BASE + new URLSearchParams({ ...URLParameters }).toString(),
      requestBody
    );
  };
}

export default HTTPSCalls;
